import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage } from "@core-ui/react-mui-core";
import bg3 from "@/assets/images/bg/bg3.png"
import { AbsoluteContentStruct } from "../common";
import { S4ContentMobile } from "./S4ContentMobile";
import { usePageWidth } from "@/hooks/usePageWidth";

export const MobileHomeSection4 = observer(() => {
  const pageWidth = usePageWidth();

  return (
    <AbsoluteContentStruct
      bgComponent={<Flex fullSize minHeight={750} center>
        <LazyImage src={bg3}
          imgStyle={{
            maxWidth: "unset",
            width: 2500,
            height: "auto",
            transform: `translateX(-${pageWidth / 64}px)`
          }} />
      </Flex>}
      contentCompnent={<S4ContentMobile />}
    />
  )
})