import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
export const BgClouds = ({ height = "100%", cloudsImageUrl = [
    "https://firebasestorage.googleapis.com/v0/b/goattap.appspot.com/o/clouds_2.png?alt=media",
    "https://firebasestorage.googleapis.com/v0/b/goattap.appspot.com/o/clouds_1.png?alt=media",
    "https://firebasestorage.googleapis.com/v0/b/goattap.appspot.com/o/clouds_3.png?alt=media"
] }) => {
    return (_jsx("div", { className: "bgclouds-container", style: {
            pointerEvents: "none", position: "relative",
            background: "rgba(0,0,0, 0.4)"
        }, children: _jsxs("div", { className: 'clouds', children: [cloudsImageUrl?.[0] && _jsx("div", { className: 'clouds-1', style: {
                        height,
                        backgroundImage: `url(${cloudsImageUrl?.[0]})`,
                    } }), cloudsImageUrl?.[1] && _jsx("div", { className: 'clouds-2', style: {
                        height,
                        backgroundImage: `url(${cloudsImageUrl?.[1]})`,
                    } }), cloudsImageUrl?.[2] && _jsx("div", { className: 'clouds-3', style: {
                        height,
                        backgroundImage: `url(${cloudsImageUrl?.[2]})`,
                    } })] }) }));
};
