import { useGlobalStyles } from "@/styles/globalStyle";
import { MOBILE_SIZE } from "@/utils/constants";
import { LazyImage, Flex, Text } from "@core-ui/react-mui-core";
import { AbsoluteContentStruct } from "./AbsoluteContentStruct";

export const Description = ({
  isMobile = false,
  title,
  content,
  bgImage
}) => {
  const globalStyles = useGlobalStyles();

  return <Flex className={isMobile ? "" : globalStyles.cardHoverMin} fullSize>
    <AbsoluteContentStruct
      bgComponent={
        <LazyImage src={bgImage}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxHeight: "unset"
          }}
          imgStyle={isMobile ? {
            maxWidth: "unset",
            width: MOBILE_SIZE,
            height: "auto",
            minHeight: "100%",
          } : {
            width: "100%",
            height: "100%",
            objectFit: "cover",
            maxHeight: "unset"
          }}
        />
      }
      contentCompnent={
        <Flex fullSize alignItems={"flex-end"}
        >
          <Flex column fullWidth
            style={{
              background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)"
            }}
            p={2}
          >
            <Text color={"#FFF"}
              className={globalStyles.hoverUnderLine}
              style={{
                fontFamily: "Roboto",
                fontSize: 40,
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "125%",
                letterSpacing: 0,
                textAlign: "start"
              }}
            >
              {title}
            </Text>
            <Text color={"#FFF"}
              style={{
                fontFamily: "Roboto",
                fontSize: 24,
                fontStyle: "normal",
                fontWeight: 450,
                lineHeight: "133.33%",
                letterSpacing: "-0.72px",
                textAlign: "start"
              }}>
              {content}
            </Text>
          </Flex>
        </Flex>
      }
    />
  </Flex>
}