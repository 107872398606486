import { jsx as _jsx } from "react/jsx-runtime";
import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
export const FadeAppear = ({ children, startOnMount = true, stiffness = 250, duration = 0.5, delay = 0, slideEffect = true, style = {}, damping = 15, mass = 1, type = "decay" }) => {
    const wrapperRef = useRef();
    const hasAnimated = useRef(false);
    const controls = useAnimation();
    useEffect(() => {
        if (wrapperRef.current && startOnMount && !hasAnimated.current) {
            controls.start({
                opacity: 1,
                transition: {
                    stiffness, duration, delay,
                    damping,
                    mass,
                    type,
                },
            });
            hasAnimated.current = true;
        }
    }, [wrapperRef.current, startOnMount, slideEffect]);
    if (slideEffect) {
        return (_jsx("div", { style: {
                width: "100%",
                height: "100%",
                overflow: "hidden",
                position: "relative",
                justifyContent: "center",
                alignItems: "center",
                ...style
            }, children: _jsx(motion.div, { ref: wrapperRef, animate: controls, initial: { opacity: 0 }, style: {
                    width: "100%",
                    height: "100%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    ...style
                }, children: children }) }));
    }
    return _jsx(motion.div, { ref: wrapperRef, animate: controls, initial: { height: 0, opacity: 0 }, style: {
            width: "100%",
            height: "100%",
            display: 'flex',
            ...style
        }, children: children });
};
