import { observer } from "@core-ui/react-mobx-state";
import { Flex, Text } from "@core-ui/react-mui-core";
import voteIcon from "@/assets/images/icons/vote.svg"
import boostIcon from "@/assets/images/icons/boost.svg"
import governIcon from "@/assets/images/icons/govern.svg"
import earnIcon from "@/assets/images/icons/earn.svg"
import { PurposeItem } from "@/components/common/PurposeItem"
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates } from "@core-ui/react-animates";
import { usePageWidth } from "@/hooks/usePageWidth";

export const S4Content = observer(() => {
  const pageWidth = usePageWidth();

  return (
    <Flex fullWidth minHeight={603} centerX>
      <Flex fullWidth maxWidth={1054} column centerY>
        <Flex mt={8}>
          <IntersectionObserverView>
            <Text color={"#FFF"}
              style={{
                textAlign: "center",
                fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                fontFamily: "Roboto Mono",
                fontSize: 56,
                fontStyle: "normal",
                fontWeight: "100",
                lineHeight: "114.286%",
                letterSpacing: "-2.24px",
              }}
            >
              <Animates.TypeWriter id="what-is-goat-for" content="What is $GOAT for?" />
            </Text>
          </IntersectionObserverView>
        </Flex>

        <IntersectionObserverView isInfinite>
          <Flex fullWidth justifyContent={"space-between"} mt={6}>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Animates.SlideRightAppear delay={0.5}>
                <PurposeItem
                  icon={voteIcon}
                  title={"Vote"}
                  description={"on Challenges to earn Voting reward"}
                  background="linear-gradient(94deg, rgba(233, 171, 62, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideRightAppear>
            </Flex>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Animates.SlideLeftAppear delay={0.5}>
                <PurposeItem
                  icon={boostIcon}
                  title={"Boost"}
                  description={"boost your Trust Score (and earning)"}
                  background="linear-gradient(94deg, rgba(62, 131, 233, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideLeftAppear>
            </Flex>
          </Flex>

          <Flex fullWidth centerY justifyContent={"space-between"} mt={4}>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Animates.SlideRightAppear delay={0.5}>
                <PurposeItem
                  icon={governIcon}
                  title={"Govern"}
                  description={"Vote to intergrate which protocols and share profit"}
                  background="linear-gradient(94deg, rgba(3, 255, 171, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideRightAppear>
            </Flex>
            <Flex justifyContent={"flex-start"} alignItems={"flex-start"}>
              <Animates.SlideLeftAppear delay={0.5}>
                <PurposeItem
                  icon={earnIcon}
                  title={"Earn"}
                  description={"airdrop and protocol fees"}
                  background="linear-gradient(94deg, rgba(233, 62, 139, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideLeftAppear>
            </Flex>
          </Flex >
        </IntersectionObserverView>
      </Flex >
    </Flex >
  )
})