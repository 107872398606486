import { Flex, LazyImage } from "@core-ui/react-mui-core"
import { AbsoluteContentStruct } from "../common";
import { FooterContent } from "./FooterContent";
import footerBg from "@/assets/images/bg/footer_bg.png"
import { FOOTER_HEIGHT } from "@/utils/constants";

export const Footer = () => {

  return <Flex fullSize bgcolor={"#000"}
    style={{
      background: `#000`
    }}
    overflow={"hidden"}
    position={"relative"}
    height={FOOTER_HEIGHT}
    center
  >
    <AbsoluteContentStruct
      bgComponent={<Flex fullSize alignItems={"flex-end"}>
        <LazyImage src={footerBg} style={{ objectFit: "cover", width: "100%", background: "#000" }} />
      </Flex>}
      contentCompnent={<Flex fullSize center height={FOOTER_HEIGHT}>
        <FooterContent />
      </Flex>}
    />
  </Flex>
}

