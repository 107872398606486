import { observer } from "@core-ui/react-mobx-state";
import { Flex, Text } from "@core-ui/react-mui-core";
import { ProfileItem } from "../common/ProfileItem";
import avt1 from "@/assets/images/avt/avt1.png"
import avt2 from "@/assets/images/avt/avt2.png"
import avt3 from "@/assets/images/avt/avt3.png"
import avt4 from "@/assets/images/avt/avt4.png"
import avt5 from "@/assets/images/avt/avt5.png"
import goatLogo from "@/assets/images/logo.png"
import { Layers } from "@/styles/layers";
import { Animates } from "@core-ui/react-animates";
import { MobileMenu } from "./MobileMenu";
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { useStore } from "@/store/index";

export const S1ContentMobile = observer(() => {
  const { uiStore } = useStore();

  return (
    <IntersectionObserverView
      stateCallback={(isElementInView?: boolean) => {
        uiStore.showFixedMenu = !isElementInView;
      }}
    >
      <Flex fullSize column centerY bgcolor={"transparent"} position={"relative"} zIndex={Layers.layer6} pt={1} pb={2}>
        <Flex fullWidth>
          <MobileMenu />
        </Flex>
        <Flex position={"absolute"} top={60} left={0} fullWidth centerY my={3} justifyContent={"space-between"} px={2}>
          <Animates.GrowUpAppear>
            <Text color={"#FFF"}
              mr={4}
              style={{
                fontFamily: 'Roboto',
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: 18,
                lineHeight: "120%",
                letterSpacing: "-0.04em",
                textAlign: "left",
              }}
            >
              Climb the Yield Mountain with your trusted Goats
            </Text>
          </Animates.GrowUpAppear>
          <Animates.GrowUpAppear>
            <Flex fullWidth justifyContent={"flex-end"}>
              <Text color={"#FFF"}
                ml={4}
                style={{
                  fontFamily: 'Roboto',
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 18,
                  lineHeight: "120%",
                  letterSpacing: "-0.04em",
                  textAlign: "right",
                }}
              >
                Stake now and let the Goats manage your funds
              </Text>
            </Flex>
          </Animates.GrowUpAppear>
        </Flex>
        <Flex mt={16}></Flex>
        <ProfileItem isMobile id={"profile-1"} mobileImageSize={80} image={goatLogo} delay={2.5} />
        <ProfileItem isMobile id={"profile-2"} mobileImageSize={140} image={avt1} delay={1.9} />
        <ProfileItem isMobile id={"profile-3"} image={avt2} delay={1.65} />
        <ProfileItem isMobile id={"profile-4"} image={avt3} delay={1.15} />
        <ProfileItem isMobile id={"profile-5"} mobileImageSize={130} image={avt4} delay={0.85} />
        <ProfileItem isMobile id={"profile-6"} mobileImageSize={130} image={avt5} delay={0.25} />
      </Flex>
    </IntersectionObserverView>
  )
})

