import { observer } from "@core-ui/react-mobx-state";
import { S1Background } from "./S1Background";
import { S1ContentMobile } from "./S1ContentMobile";
import { AbsoluteContentStruct } from "../common/AbsoluteContentStruct";

export const MobileHomeSection1 = observer(() => {
  return (
    <AbsoluteContentStruct
      bgComponent={<S1Background isMobile />}
      contentCompnent={
        <S1ContentMobile />
      }
    />
  )
})