import { useGlobalStyles } from "@/styles/globalStyle";
import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, Text, useResponsive } from "@core-ui/react-mui-core";
import triangleFill from "@/assets/images/icons/triangle_fill.svg"
import triangleOutlined from "@/assets/images/icons/triangle_outlined.svg"
import { CONTENT_MAX_WIDTH, PAGE_MAX_WIDTH } from "@/utils/constants";
import { GoatFiItem } from "../common/GoatFiItem";
import { MapItem } from "../common/MapItem";
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates } from "@core-ui/react-animates";
import cloud1 from "@/assets/images/icons/cloud1.png"
import cloud2 from "@/assets/images/icons/cloud2.png"
import cloud3 from "@/assets/images/icons/cloud3.png"
import cloud4 from "@/assets/images/icons/cloud4.png"
import bgs2_2 from "@/assets/images/bg/bgs2_2.png"
import { usePageWidth } from "@/hooks/usePageWidth";
import { Layers } from "@/styles/layers";

export const S2Content = observer(() => {
  const globalStyles = useGlobalStyles();
  const pageWidth = usePageWidth();
  const { miniWindow } = useResponsive({ miniWindow: 1500 });

  return (
    <Flex fullSize center position={"relative"} mt={6}>
      <Flex position={"absolute"} zIndex={Layers.layer2} left={0} top={`${pageWidth * 750 / PAGE_MAX_WIDTH}px`}>
        <LazyImage src={cloud1} />
      </Flex>
      <Flex position={"absolute"} zIndex={Layers.layer2} left={`${pageWidth * 280 / PAGE_MAX_WIDTH}px`} bottom={`${pageWidth * 180 / PAGE_MAX_WIDTH}px`}>
        <LazyImage src={cloud2} />
      </Flex>
      <Flex position={"absolute"} zIndex={Layers.layer2} left={`${pageWidth * 300 / PAGE_MAX_WIDTH}px`} top={`${pageWidth * 900 / PAGE_MAX_WIDTH}px`}>
        <LazyImage src={cloud3} />
      </Flex>
      <Flex position={"absolute"} zIndex={Layers.layer2} right={0} top={`${pageWidth * 900 / PAGE_MAX_WIDTH}px`}>
        <LazyImage src={cloud4} />
      </Flex>

      <Flex position={"absolute"} left={"16px"} bottom={24}
        maxWidth={200}
        zIndex={Layers.layer2}
        column
      >
        <Text color={"#FFF"}
          style={{
            fontFamily: 'Roboto Mono',
            fontStyle: "normal",
            fontWeight: 100,
            fontSize: 56,
            lineHeight: "114%",
            letterSpacing: "-0.04em",
            whiteSpace: "wrap",
            textAlign: "left",
            fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
          }}
        >
          Core - technology
        </Text>
      </Flex>

      <Flex fullSize column alignItems={"flex-start"} zIndex={Layers.layer3} justifyContent={"flex-start"} maxWidth={CONTENT_MAX_WIDTH}>
        <Flex fullWidth
          bgcolor={"linear-gradient(2.39deg, rgba(0, 0, 0, 0.52) 1.94%, rgba(99, 111, 250, 0.27) 40.25%, rgba(68, 79, 205, 0) 77.72%)"}
          style={{
            background: "linear-gradient(2.39deg, rgba(0, 0, 0, 0.52) 1.94%, rgba(99, 111, 250, 0.27) 40.25%, rgba(68, 79, 205, 0) 77.72%)"
          }}
          py={2}
          minHeight={375}
          zIndex={Layers.layer3}
        >
          <Flex fullWidth centerY justifyContent={"space-between"} px={4}>
            <IntersectionObserverView>
              <Flex centerY>
                <Text className={globalStyles.textRoboto56} color="#FFF"
                  style={{
                    fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                    fontFamily: "Roboto Mono",
                    fontSize: 56,
                    fontStyle: "normal",
                    fontWeight: "100",
                    lineHeight: "114.286%",
                    letterSpacing: "-2.24px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Animates.TypeWriter id="goat-fi" content="Goat - fi" />
                </Text>
              </Flex>
            </IntersectionObserverView>

            <Flex fullWidth>
              <IntersectionObserverView isInfinite>
                {!miniWindow && <Flex centerY style={{ transform: `translateX(${pageWidth * 200 / PAGE_MAX_WIDTH}px)` }}>
                  <Flex centerY>
                    <GoatFiItem
                      icon={triangleFill}
                      id={'item-1'}
                      delay={0.75}
                      triangleDelay={1.75}
                      title="EASY ON BOARDING"
                      description="Simply stake any type of token, lock for at least 1 week and unstake your principal any time afterwards along with shared profit"
                      titleStyle={{
                        fontSize: 44,
                        letterSpacing: "-1.76px",
                      }}
                      descriptionStyle={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontFeatureSettings: "unset",
                        letterSpacing: "-0.32px",
                        maxWidth: 380,
                        whiteSpace: "wrap",
                      }}
                    />
                  </Flex>

                  <Flex column style={{ transform: "translateX(-200px)" }}>
                    <Flex centerY ml={8} mb={4}>
                      <GoatFiItem
                        delay={0.25}
                        triangleDelay={0.75}
                        id={'item-2'}
                        icon={triangleOutlined}
                        title="YIELD OPTIMIZING"
                        description="Increase your portfolio value in USD or ETH with the best yields from Pendle (and more) and the best Goats (professional earners)"
                        titleStyle={{
                          fontSize: 44,
                          letterSpacing: "-1.76px",
                        }}
                        descriptionStyle={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontFeatureSettings: "unset",
                          letterSpacing: "-0.32px",
                          maxWidth: 380,
                          whiteSpace: "wrap",
                        }}
                      />
                    </Flex>

                    <Flex centerY ml={8} style={{ opacity: 0 }}>
                      <GoatFiItem
                        delay={0.75}
                        triangleDelay={1.75}
                        id={'item-3'}
                        icon={triangleFill}
                        title="EASY ON BOARDING"
                        description="Simply stake any type of token, lock for at least 1 week and unstake your principal any time afterwards along with shared profit"
                        titleStyle={{
                          fontSize: 44,
                          letterSpacing: "-1.76px",
                        }}
                        descriptionStyle={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontFeatureSettings: "unset",
                          letterSpacing: "-0.32px",
                          maxWidth: 380,
                          whiteSpace: "wrap",
                        }}
                      />
                    </Flex>

                    <Flex centerY ml={8} mt={4}>
                      <GoatFiItem
                        id={'item-4'}
                        icon={triangleOutlined}
                        title="FUND SECURITY"
                        delay={1.25}
                        triangleDelay={2.75}
                        description="Only you can withdraw your own funds, which earn yields only from whitelisted, trusted platforms such as Pendle (and more)"
                        titleStyle={{
                          fontSize: 44,
                          letterSpacing: "-1.76px",
                        }}
                        descriptionStyle={{
                          fontFamily: "Roboto",
                          fontSize: 16,
                          fontFeatureSettings: "unset",
                          letterSpacing: "-0.32px",
                          maxWidth: 380,
                          whiteSpace: "wrap",
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>}

                {miniWindow && <Flex column>
                  <Flex centerY mb={4}>
                    <GoatFiItem
                      id={'item-5'}
                      delay={0.25}
                      triangleDelay={0.75}
                      icon={triangleOutlined}
                      title="YIELD OPTIMIZING"
                      description="Increase your portfolio value in USD or ETH with the best yields from Pendle (and more) and the best Goats (professional earners)"
                      titleStyle={{
                        fontSize: 44,
                        letterSpacing: "-1.76px",
                      }}
                      descriptionStyle={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontFeatureSettings: "unset",
                        letterSpacing: "-0.32px",
                        maxWidth: 380,
                        whiteSpace: "wrap",
                      }}
                    />
                  </Flex>

                  <Flex centerY >
                    <GoatFiItem
                      id={'item-6'}
                      delay={0.75}
                      triangleDelay={1.75}
                      icon={triangleFill}
                      title="EASY ON BOARDING"
                      description="Simply stake any type of token, lock for at least 1 week and unstake your principal any time afterwards along with shared profit"
                      titleStyle={{
                        fontSize: 44,
                        letterSpacing: "-1.76px",
                      }}
                      descriptionStyle={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontFeatureSettings: "unset",
                        letterSpacing: "-0.32px",
                        maxWidth: 380,
                        whiteSpace: "wrap",
                      }}
                    />
                  </Flex>

                  <Flex centerY mt={4}>
                    <GoatFiItem
                      id={'item-7'}
                      icon={triangleOutlined}
                      title="FUND SECURITY"
                      delay={1.25}
                      triangleDelay={2.75}
                      description="Only you can withdraw your own funds, which earn yields only from whitelisted, trusted platforms such as Pendle (and more)"
                      titleStyle={{
                        fontSize: 44,
                        letterSpacing: "-1.76px",
                      }}
                      descriptionStyle={{
                        fontFamily: "Roboto",
                        fontSize: 16,
                        fontFeatureSettings: "unset",
                        letterSpacing: "-0.32px",
                        maxWidth: 380,
                        whiteSpace: "wrap",
                      }}
                    />
                  </Flex>
                </Flex>}

              </IntersectionObserverView>
            </Flex>
          </Flex>
        </Flex>


        <Flex fullWidth position={"relative"} mt={4}>
          <div id="top-core-tech"></div>
          <Flex flex={1} justifyContent={"flex-start"} minHeight={200} style={{ transform: "translateY(550px)" }}>
            <IntersectionObserverView isInfinite>
              <MapItem
                delay={0.25}
                title="Strategy maps"
                description="A comprehensive, interactive map of yield strategies that help you make better decisions and help Goats adjust strategies flexibly"
              />
            </IntersectionObserverView>
          </Flex>
          <Flex flex={1} justifyContent={"flex-start"} minHeight={200} style={{ transform: "translateY(350px) translateX(-100px)" }}>
            <IntersectionObserverView isInfinite>
              <MapItem
                delay={0.35}
                title="Trust Score"
                description="A measure of reputation reimagined to be fully on-chain and socially validated, to help you vet Goats more accurately"
              />
            </IntersectionObserverView>
          </Flex>
          <Flex flex={1} justifyContent={"flex-start"} minHeight={200} style={{ transform: "translateY(200px) translateX(-120px)" }}>
            <IntersectionObserverView isInfinite>
              <MapItem
                delay={0.45}
                title="Goat lend"
                description="Borrow while your locked funds continue to grow, against the future value of your growing portfolio value, at lower rate thanks to Trust Score"
              />
            </IntersectionObserverView>
          </Flex>
        </Flex>
      </Flex >

      <Flex position={"absolute"} bottom={0} fullWidth zIndex={Layers.layer3}>
        <LazyImage src={bgs2_2} imgStyle={{ width: "100%" }} />
      </Flex>
    </Flex >
  )
})