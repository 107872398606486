import { Flex } from "@core-ui/react-mui-core"
import { MobileFooterContent } from "./MobileFooterContent";

export const MobileFooter = () => {

  return <Flex fullSize bgcolor={"#000"}
    style={{
      background: `#000`
    }}
    center
  >
    <Flex fullSize center column>
      <MobileFooterContent />
    </Flex>
  </Flex>
}

