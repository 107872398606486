import { Animates } from "@core-ui/react-animates";
import { observer } from "@core-ui/react-mobx-state";
import { Flex } from "@core-ui/react-mui-core";
// import "@core-ui/react-animates/dist/bgCloud2.style.css"
import "@core-ui/react-animates/dist/waterReflection.style.css"
import bg1Full from "@/assets/images/bg/bg1_full.png"
import { LazyImage } from "@core-ui/react-mui-core";
// import cloudBg1 from "@/assets/images/bg/clouds_1.png"
// import cloudBg2 from "@/assets/images/bg/clouds_2.png"
// import cloudBg3 from "@/assets/images/bg/clouds_3.png"
import { MOBILE_SIZE } from "@/utils/constants";
import { Layers } from "@/styles/layers";

export const S1Background = observer(({
  isMobile
}: {
  isMobile?: boolean;
}) => {

  return (
    <Flex fullSize width={"100%"} column>
      <Flex fullSize
        position={"absolute"}
        // bgcolor={"rgba(161, 194, 194, 0.1)"}
        overflow={"hidden"}
        className="bg-1"
        zIndex={Layers.layer1}
        style={isMobile ? {
          // transform: "translateY(-60px)",
          pointerEvents: "none"
        } : {
          pointerEvents: "none"
        }}
      >
        <Flex fullSize height={"30%"} position={"relative"} style={{
          pointerEvents: "none",
          transform: "translate(0, -10%)",
          opacity: 0.4
        }}>
          {/* <Animates.BgClouds cloudsImageUrl={[
            cloudBg1,
            cloudBg2,
            cloudBg3
          ]} /> */}
          {/* <Animates.BgClouds2 id="top-bg" background="transparent"/> */}
        </Flex>
      </Flex>
      <Flex fullWidth column bgcolor={"transparent"}>
        <Flex fullWidth center bgcolor={"transparent"}>
          <LazyImage
            src={bg1Full} style={{ width: "100%", minWidth: isMobile ? MOBILE_SIZE * 685 / 364 : "unset" }}
          />
        </Flex>
      </Flex>
      <Flex fullWidth height={250} style={{ overflow: "hidden" }} bgcolor={"rgb(161, 194, 194)"} className="bg-1" >
        <Flex fullWidth position={"relative"} >
          <Animates.WaterReflection bgImage={bg1Full} ratio={25}
            bgStyle={isMobile ? {
              backgroundSize: "2056px 100%"
            } : {}}
          />
        </Flex>
      </Flex>
    </Flex>
  )
})