import { observer } from "@core-ui/react-mobx-state";
import { Flex } from "@core-ui/react-mui-core";
import { MobileHomeSection1 } from "@/components/home/MobileHomeSection1";
import { MobileHomeSection2 } from "@/components/home/MobileHomeSection2";
import { MobileHomeSection3 } from "@/components/home/MobileHomeSection3";
import { MobileHomeSection4 } from "@/components/home/MobileHomeSection4";
import { MobileFooter } from "@/components/home/MobileFooter";
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { MobileHomeSection5 } from "@/components/home/MobileHomeSection5";
import { useStore } from "@/store/index";
import { MobileMenu } from "@/components/home/MobileMenu";

const HomeContainer = observer(() => {
  const { uiStore } = useStore();

  return (
    <>
      {uiStore.showFixedMenu && <Flex position={"fixed"} top={16} zIndex={9999} center fullWidth>
        <MobileMenu isFixedMenu />
      </Flex>}
      <Flex fullWidth column centerY position={"relative"} height={"100vh"} bgcolor={"#000000"}
        style={{ overflowX: "hidden", overflowY: "auto" }}
      >
        <Flex fullWidth position={"relative"} column maxWidth={PAGE_MAX_WIDTH}>
        <div id="top"></div>
          <MobileHomeSection1 />
          <div id="top-goat-fi"></div>
          <MobileHomeSection2 />
          <div id="top-core-tech"></div>
          <MobileHomeSection3 />
          <MobileHomeSection4 />
          <MobileHomeSection5 />
          <Flex fullWidth bgcolor={"#000000"}>
            <MobileFooter />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
});

export default HomeContainer;
