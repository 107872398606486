import { useGlobalStyles } from "@/styles/globalStyle";
import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, OutlinedButton, Text, useResponsive } from "@core-ui/react-mui-core";
import { Drawer, Box, List, ListItem, ListItemButton, Select, MenuItem } from "@core-ui/react-mui-core/materials";
import logoFull from "@/assets/images/logo_full.png"
import menuIcon from "@/assets/images/icons/menu.svg"
import closeIcon from "@/assets/icons/close.svg"
import { useStore } from "@/store/index";
import { Animates } from "@core-ui/react-animates";
import { usePageWidth } from "@/hooks/usePageWidth";
import { Layers } from "@/styles/layers";
import { goToSection } from "@/utils/index";

export const MobileMenu = observer(({
  isFixedMenu
}: {
  isFixedMenu?: boolean;
 
}) => {
  const globalStyles = useGlobalStyles();
  const { minSize } = useResponsive({
    minSize: 360
  });
  const { uiStore } = useStore();
  const { tabletSizeDown } = useResponsive();

  const menuContent = <Flex
    borderRadius={"16px"}
    mx={1}
    padding={"8px"}
    centerY
    border={"solid 2px #0550a590"}
    className={globalStyles.backDropFilterBlur10}
    bgcolor={"#00000066"}
    style={{
      backdropFilter: "blur(10px)",
      WebkitBackdropFilter: "blur(10px)",
      background: "#00000066",
      // backgroundImage: `url(${frameLinearBg})`,
      // backgroundSize: "100% 100%",
      // backgroundRepeat: "no-repeat",
      // backgroundPosition: "center",
    }}
    fullWidth
    justifyContent={"space-between"}
  >
    <Flex centerY>
      <Flex mr={2}
        className={globalStyles.hoveredBtn}
        onClick={() => {
          uiStore.openMobileMenu = !uiStore.openMobileMenu
        }}
        cursorPointer
        border={"solid 1px #0550a590"}
        p={1}
        borderRadius={"16px"}
        style={{
          // transform: uiStore.openMobileMenu ? `translateX(${pageWidth - 90}px)` : "none",
          // transitionDuration: "400ms",
        }}
      >
        <LazyImage src={menuIcon} style={{ width: 24, height: 24 }} />
      </Flex>

      {minSize && !uiStore.openMobileMenu && <Flex centerY cursorPointer onClick={() => {
        document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
      }}>
        <LazyImage src={logoFull} style={{ width: 140, height: 24 }} />
      </Flex>}
    </Flex>

    {!minSize && !uiStore.openMobileMenu && <Flex centerY cursorPointer onClick={() => {
      document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
    }}>
      <LazyImage src={logoFull} style={{ width: 160, height: 28 }} />
    </Flex>}

    {!uiStore.openMobileMenu && !minSize && <Flex centerY>
      <OutlinedButton style={{
        background: "#3858F5",
        border: "none",
        padding: "4px 18px",
        height: "fit-content",
        borderRadius: "8px"
      }}>
        <Text className={globalStyles.textRoboto16} style={{ whiteSpace: "nowrap" }}>{tabletSizeDown ? "Launch" : "Launch app"}</Text>
      </OutlinedButton>
    </Flex>}
  </Flex>

  const AppearAnimComponent = isFixedMenu ? Animates.GrowDownAppear : Animates.GrowDownAppear

  return (
    <AppearAnimComponent type="decay">
      <Flex position={"relative"} fullWidth>
        {menuContent}
        <Flex position={"absolute"} fullSize zIndex={Layers.layer12}>
          {menuContent}
        </Flex>
      </Flex>
      <Drawer open={!!uiStore.openMobileMenu} onClose={() => { uiStore.openMobileMenu = false }}
        anchor={"left"}
      >
        <Flex fullSize column bgcolor={"#000"} minWidth={278}>
          <Flex centerY fullWidth justifyContent={"space-between"} p={1}>
            <Flex centerY cursorPointer onClick={() => {
              document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
            }}>
              <LazyImage src={logoFull} style={{ width: 180, height: 30 }} />
            </Flex>
            <Flex cursorPointer center p={0.5} onClick={() => { uiStore.openMobileMenu = false }}>
              <img src={closeIcon} style={{ width: 24, height: 24 }} />
            </Flex>
          </Flex>

          <Box
            sx={{ width: 278, background: "#000" }}
            role="presentation"
          >
            <List sx={{ background: "#000" }}>
              <ListItem>
                <Flex cursorPointer onClick={() => { goToSection("top-goat-fi") }}>
                  <Text ml={1} className={globalStyles.textRoboto14}>Goat-fi</Text>
                </Flex>
                {/* <Select value="1">
                  <MenuItem value={1}><Text className={globalStyles.textRoboto14}>Goat-fi</Text></MenuItem>
                </Select> */}
              </ListItem>
              <ListItem>
                <Flex cursorPointer onClick={() => { goToSection("top-core-tech") }}>
                  <Text ml={1} className={globalStyles.textRoboto14}>Core Technology</Text>
                </Flex>
                {/* <Select value="1">
                  <MenuItem value={1}><Text className={globalStyles.textRoboto14}>Core Technology</Text></MenuItem>
                </Select> */}
              </ListItem>
              <ListItem onClick={() => { goToSection("top") }}>
                <Text ml={1} className={globalStyles.textRoboto14}>$GOAT</Text>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton>
                  <OutlinedButton style={{
                    background: "#3858F5",
                    border: "none",
                    width: "100%"
                  }}>
                    <Text className={globalStyles.textRoboto16} style={{ whiteSpace: "nowrap" }}>Launch app</Text>
                  </OutlinedButton>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Flex>
      </Drawer>
    </AppearAnimComponent>
  )
})