import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { loadAll } from "@tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.
// import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
import { polygonMask } from "./configs";
export const PolygonMask = ({ config, id, children, }) => {
    const [init, setInit] = useState(false);
    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            await loadFull(engine);
            // await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);
    const particlesLoaded = async (container) => {
        console.log(container);
    };
    const options = useMemo(() => ({
        ...polygonMask,
        polygon: {
            ...polygonMask.polygon,
            url: config.image || polygonMask.polygon
        }
    }), []);
    if (init) {
        return (_jsxs("div", { style: {
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
            }, children: [_jsx("div", { style: {
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                    }, children: _jsx(Particles, { id: `polygon-mask-${id}`, className: "polygon-mask", style: {
                            width: "100%",
                            height: "100%",
                        }, particlesLoaded: particlesLoaded, options: options }) }), _jsx("div", { style: {
                        width: "100%",
                        height: "100%",
                        zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center"
                    }, children: children && children })] }));
    }
    return _jsx("div", { style: {
            width: "100%",
            height: "100%",
            zIndex: 2, display: "flex", justifyContent: "center", alignItems: "center"
        }, children: children && children });
};
