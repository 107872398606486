import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from "react";
import { useDelayedDisplay } from "@core-utils/react-hooks";
import { useAnimationWrapperRef } from "./hooks/useAnimationWrapperRef";
export var AnimationView;
(function (AnimationView) {
    AnimationView.FadeInRTL = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay ? "animate-fade_in_rtl" : "hidden"} ${!isDisplay && "hidden"} ${className}`, children: children }));
    });
    AnimationView.FadeIn = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay ? `animate-fade_in ${className}` : "hidden"}`, children: children }));
    });
    AnimationView.FadeInLTR = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay ? "animate-fade_in_ltr" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.SlideIn = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay ? "animate-slide_in" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.SlideLTR = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: ` ${isDisplay ? "animate-slide_ltr" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.SlideDown = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: ` ${isDisplay ? "animate-slide_down" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.SlideUp = forwardRef(({ children, className = "", id = "", delay = 0 }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: ` ${isDisplay ? `animate-slide_up ${className}` : "hidden"}`, children: children }));
    });
    AnimationView.ItemSlide = forwardRef(({ children, className = "", id = "", delay = 0 }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: ` ${isDisplay ? `animate-slide_item ${className}` : "hidden"}`, children: children }));
    });
    AnimationView.Bird = forwardRef(({ children, className, id, disabled, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay && !disabled ? `animate-bird ${className}` : "hidden"} `, children: children }));
    });
    AnimationView.TextAppearance = forwardRef(({ children, className, id, disabled, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay && !disabled ? "animate-text_appear" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.SpaceAppear = forwardRef(({ children, className, id, disabled, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: `${isDisplay && !disabled ? "animate-space_appear" : "hidden"} ${className}`, children: children }));
    });
    AnimationView.LoadingPage = forwardRef(({ children, className, id, delay }, ref) => {
        const isDisplay = useDelayedDisplay(delay);
        const wrapperRef = useAnimationWrapperRef(ref);
        return (_jsx("div", { id: id, ref: wrapperRef, className: ` ${isDisplay ? "animate-loading_page" : "hidden"} ${className}`, children: children }));
    });
})(AnimationView || (AnimationView = {}));
