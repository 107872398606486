import { observer } from "@core-ui/react-mobx-state";
import { Flex } from "@core-ui/react-mui-core";
import bgs2 from "@/assets/images/bg/bgs2.png"
import { S2Content } from "./S2Content";
import { useGlobalStyles } from "@/styles/globalStyle";

export const HomeSection2 = observer(() => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex fullWidth column bgcolor={"#000"} minHeight={1660} position={"relative"}
      className={globalStyles.backDropFilterBlur4}
      style={{
        backgroundImage: `url(${bgs2})`,
        objectFit: "cover",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backdropFilter: "blur(4px)",
        WebkitBackdropFilter: "blur(4px)",
      }}
    >
      <S2Content />
    </Flex>
  )
})