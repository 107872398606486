import { useGlobalStyles } from "@/styles/globalStyle";
import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core";
import triangleFill from "@/assets/images/icons/triangle_fill.svg"
import triangleOutlined from "@/assets/images/icons/triangle_outlined.svg"
import { CONTENT_MAX_WIDTH } from "@/utils/constants";
import { GoatFiItem } from "../common/GoatFiItem";
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates } from "@core-ui/react-animates";
import cloud1 from "@/assets/images/icons/cloud1.png"
import cloud3 from "@/assets/images/icons/cloud3.png"

export const S2ContentMobile = observer(() => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex fullSize center my={10} position={"relative"}>
      <Flex position={"absolute"} left={0} bottom={650}>
        <LazyImage src={cloud1} />
      </Flex>
      <Flex position={"absolute"} right={-140} bottom={760}>
        <LazyImage src={cloud3} />
      </Flex>
      <Flex fullSize column alignItems={"flex-start"} justifyContent={"flex-start"} maxWidth={CONTENT_MAX_WIDTH}
        px={2}
      >
        <Flex fullWidth
          bgcolor={"linear-gradient(2.39deg, rgba(0, 0, 0, 0.52) 1.94%, rgba(99, 111, 250, 0.27) 40.25%, rgba(68, 79, 205, 0) 77.72%)"}
          style={{
            background: "linear-gradient(2.39deg, rgba(0, 0, 0, 0.52) 1.94%, rgba(99, 111, 250, 0.27) 40.25%, rgba(68, 79, 205, 0) 77.72%)"
          }}
          pb={3}
          minHeight={375}
          borderRadius={"8px"}
        >
          <Flex column fullWidth centerY>
            <IntersectionObserverView>
              <Flex center mb={2}>
                <Text className={globalStyles.textRoboto56} color="#FFF"
                  style={{
                    fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                    fontFamily: "Roboto Mono",
                    fontSize: 44,
                    fontStyle: "normal",
                    fontWeight: "100",
                    lineHeight: "114.286%",
                    letterSpacing: "-2.24px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Animates.TypeWriter id="goat-fi" content="Goat - fi" />
                </Text>
              </Flex>
            </IntersectionObserverView>
            <IntersectionObserverView isInfinite>
              <Flex centerY p={1} mt={2}>
                <GoatFiItem
                  id={'item-1'}
                  isMobile
                  delay={0.25}
                  triangleDelay={0.75}
                  icon={triangleOutlined}
                  title="YIELD OPTIMIZING"
                  description="Increase your portfolio value in USD or ETH with the best yields from Pendle (and more) and the best Goats (professional earners)"
                  titleStyle={{
                    fontSize: 24,
                    letterSpacing: "-1.76px",
                  }}
                  descriptionStyle={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    fontFeatureSettings: "unset",
                    letterSpacing: "-0.32px",
                    maxWidth: 380,
                    whiteSpace: "wrap",
                  }}
                />
              </Flex>
              <Flex centerY p={1} mt={2}>
                <GoatFiItem
                  id={'item-2'}
                  isMobile
                  icon={triangleFill}
                  delay={0.75}
                  triangleDelay={1.75}
                  title="EASY ON BOARDING"
                  description="Simply stake any type of token, lock for at least 1 week and unstake your principal any time afterwards along with shared profit"
                  titleStyle={{
                    fontSize: 24,
                    letterSpacing: "-1.76px",
                  }}
                  descriptionStyle={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    fontFeatureSettings: "unset",
                    letterSpacing: "-0.32px",
                    maxWidth: 380,
                    whiteSpace: "wrap",
                  }}
                />
              </Flex>
              <Flex centerY p={1} mt={2}>
                <GoatFiItem
                  id={'item-3'}
                  isMobile
                  icon={triangleOutlined}
                  delay={1.25}
                  triangleDelay={2.75}
                  title="FUND SECURITY"
                  description="Only you can withdraw your own funds, which earn yields only from whitelisted, trusted platforms such as Pendle (and more)"
                  titleStyle={{
                    fontSize: 24,
                    letterSpacing: "-1.76px",
                  }}
                  descriptionStyle={{
                    fontFamily: "Roboto",
                    fontSize: 14,
                    fontFeatureSettings: "unset",
                    letterSpacing: "-0.32px",
                    maxWidth: 380,
                    whiteSpace: "wrap",
                  }}
                />
              </Flex>
            </IntersectionObserverView>
          </Flex>
        </Flex>
      </Flex >
    </Flex >
  )
})