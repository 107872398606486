import { Link } from "@core-ui/react-core"
import { AppTheme, Flex, LazyImage, Text } from "@core-ui/react-mui-core"
import logoFull from "@/assets/images/logo_full.png"
import ytubeIcon from "@/assets/images/icons/youtube.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import twitterIcon from "@/assets/images/icons/twitter.svg";
import instagramIcon from "@/assets/images/icons/instagram.png";
import linkedinIcon from "@/assets/images/icons/linkedin.png";
import { makeStyles, createStyles } from "@core-ui/react-mui-core/style"
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { useGlobalStyles } from "@/styles/globalStyle";

const textStyle = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.02em',
  fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
  color: '#FAFAFA',
  fontVariationSettings: "'slnt' 0",
}

export const MobileFooterContent = () => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex zIndex={1} bgcolor={"#101010"} column p={4} center fullWidth>
      <Flex fullWidth maxWidth={PAGE_MAX_WIDTH} justifyContent={'space-between'} column>

        <Flex column fullWidth centerY>
          <Flex centerY cursorPointer onClick={() => {
            document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
          }}>
            <LazyImage src={logoFull} style={{ height: 40, width: 240 }} />
          </Flex>
          <Flex centerY mt={2}>
            <Flex mx={1} className={globalStyles.iconHover}>
              <Link to="https://youtube.com">
                <LazyImage src={ytubeIcon} style={{ width: 24, height: 24 }} />
              </Link>
            </Flex>
            <Flex mx={1} className={globalStyles.iconHover}>
              <Link to="https://facebook.com">
                <LazyImage src={facebookIcon} style={{ width: 24, height: 24 }} />
              </Link>
            </Flex>
            <Flex mx={1} className={globalStyles.iconHover}>
              <Link to="https://twiiter.com">
                <LazyImage src={twitterIcon} style={{ width: 24, height: 24 }} />
              </Link>
            </Flex>
            <Flex mx={1} className={globalStyles.iconHover}>
              <Link to="https://instagram.com">
                <LazyImage src={instagramIcon} style={{ width: 24, height: 24 }} />
              </Link>
            </Flex>
            <Flex ml={1} className={globalStyles.iconHover}>
              <Link to="https://linkedin.com">
                <LazyImage src={linkedinIcon} style={{ width: 24, height: 24 }} />
              </Link>
            </Flex>
          </Flex>

          <Flex height={2} mt={4} fullWidth bgcolor={"rgba(255,255,255, 0.1)"}></Flex>
          <Flex fullWidth centerX justifyItems={"flex-end"} mt={4}>
            <Text style={{ opacity: 0.5 }} color="white">© 2024 All Rights Reserved.</Text>
          </Flex>
          <Flex centerY>
              <Flex className={globalStyles.hoverUnderLine} mx={1}>
                <Text style={{
                  ...textStyle,
                  fontSize: 16,
                }} color={"#FAFAFA"}>
                  Fourteen
                </Text>
              </Flex>
              <Flex className={globalStyles.hoverUnderLine} mx={1}>
                <Text style={{
                  ...textStyle,
                  fontSize: 16,
                }} color={"#FAFAFA"}>
                  Fifteen
                </Text>
              </Flex>
              <Flex className={globalStyles.hoverUnderLine} ml={1}>
                <Text style={{
                  ...textStyle,
                  fontSize: 16,
                }} color={"#FAFAFA"}>
                  Sixteen
                </Text>
              </Flex>
            </Flex>
        </Flex>
      </Flex>
    </Flex >
  )
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    socialIcon: {
      ...theme.autoTransformWhenHolver,
    },
  })
);
