import { Layers } from "@/styles/layers"
import { Flex, Loading } from "@core-ui/react-mui-core"
import logoFull from "@/assets/images/logo_full.png"
import { Animates } from "@core-ui/react-animates"
import bgImg from "@/assets/images/bg/bg1_full.png"
import { PAGE_LOAD_TIME } from "@/containers/home"

export const LoadingPage = ({ suspenseLoading = false }) => {

  return <Flex position={"fixed"} width={"100vw"} height={"100vh"} zIndex={Layers.layerMax}
    centerY
    column
    bgcolor={"#000"}
    p={2}
  >
    {!suspenseLoading && <>
      <Flex fullSize center>
        <Animates.BlurringBgLoading id="appbg" bgImage={bgImg} duration={PAGE_LOAD_TIME}>
          <Flex fullSize column centerY>
            <img src={logoFull} style={{ height: 40, width: 240 }} />
            <Flex fullSize center>
              <Animates.LoadingIcon2 />
            </Flex>
          </Flex>
        </Animates.BlurringBgLoading>
      </Flex>
    </>}
  </Flex>
}