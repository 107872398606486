import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
export const BlurringBgLoading = ({ containerStyle = {}, children, bgImage, id, duration }) => {
    const [initial, setInitial] = useState(false);
    useEffect(() => {
        const bg = document.getElementById(`bg-${id}`);
        if (!bg || !duration)
            return () => { };
        setInitial(true);
        let load = 0;
        let int = setInterval(blurring, (duration - 1000) / 100);
        const scale = (num, in_min, in_max, out_min, out_max) => {
            return ((num - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
        };
        function blurring() {
            load++;
            if (load > 99) {
                clearInterval(int);
            }
            bg.style.filter = `blur(${scale(load, 0, 100, 30, 0)}px)`;
        }
    }, [id, duration]);
    return _jsxs("div", { style: {
            width: "100%",
            height: "100%",
            overflow: "hidden",
            position: "relative",
            ...containerStyle
        }, children: [_jsx("section", { className: "bg", id: `bg-${id}`, style: {
                    background: initial ? `url(${bgImage})
        no-repeat center center/cover` : "#000",
                    position: "absolute",
                    top: "-30px",
                    left: "-30px",
                    width: "calc(100% + 60px)",
                    height: "calc(100% + 60px)",
                    zIndex: "-1px",
                    filter: "blur(0px)"
                } }), _jsx("div", { style: {
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                }, children: children })] });
};
