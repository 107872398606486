import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core";
import antidumping from "@/assets/images/bg/antidumping.png"
import burning from "@/assets/images/bg/burning.png"
import halving from "@/assets/images/bg/halving.png"
import endlessDemand from "@/assets/images/bg/endless_emand.png"
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates, PolygonMask } from "@core-ui/react-animates";
import { Description } from "../common/Description";
import footerBg from "@/assets/images/bg/footer_bg.png"
import { usePageWidth } from "@/hooks/usePageWidth";
import { MOBILE_SIZE } from "@/utils/constants";

export const MobileHomeSection5 = observer(() => {
  const pageWidth = usePageWidth();

  return (
    <Flex fullWidth column center>
      <PolygonMask id="s5-mobi" config={{}}>
        <Flex fullWidth>
          <Flex fullWidth column mt={6}>
            <Text color={"#FFF"} px={1}
              style={{
                fontFamily: 'Roboto Mono',
                fontStyle: "normal",
                fontWeight: 100,
                fontSize: 44,
                lineHeight: "64px",
                letterSpacing: "-0.04em",
                fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                textAlign: "center"
              }}
            >
              What is $GOAT scarce?
            </Text>

            <IntersectionObserverView>
              <Flex fullWidth overflow={"hidden"} px={1}>
                <Animates.SlideRightAppear delay={0.5}>
                  <Flex fullSize borderRadius={"16px"} mt={2} maxHeight={550} minHeight={250}
                    overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                    <Description title={"Endless Demand"}
                      isMobile
                      bgImage={endlessDemand}
                      content="more and more token is required to maximize Trust Score and win Challenges"
                    />
                  </Flex>
                </Animates.SlideRightAppear>
              </Flex>

              <Flex fullWidth overflow={"hidden"} px={1}>
                <Animates.SlideLeftAppear delay={1}>
                  <Flex fullSize borderRadius={"16px"} mt={2} maxHeight={550} minHeight={250}
                    overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                    <Description title={"Anti-dumping"}
                      isMobile
                      bgImage={antidumping}
                      content="$GOAT stakers earn the penalty paid by early $GOAT unstakers, slowing down $GOAT unstaking"
                    />
                  </Flex>
                </Animates.SlideLeftAppear>
              </Flex>

              <Flex fullWidth overflow={"hidden"} px={1}>
                <Animates.SlideRightAppear delay={1.5}>
                  <Flex fullSize borderRadius={"16px"} mt={4} maxHeight={550} minHeight={250}
                    overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                    <Description title={"Burning"}
                      isMobile
                      bgImage={burning}
                      content="all fees collected in $GOAT are burned"
                    />
                  </Flex>
                </Animates.SlideRightAppear>
              </Flex>

              <Flex fullWidth overflow={"hidden"} px={1}>
                <Animates.SlideLeftAppear delay={2}>
                  <Flex fullSize borderRadius={"16px"} mt={6} maxHeight={550} minHeight={250}
                    overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                    <Description title={"Halving"}
                      isMobile
                      bgImage={halving}
                      content="emission is halved every 24 months"
                    />
                  </Flex>
                </Animates.SlideLeftAppear>
              </Flex>
            </IntersectionObserverView>

            <Flex fullWidth overflow={"hidden"}>
              <LazyImage src={footerBg} width={MOBILE_SIZE} height={"auto"} imgStyle={{
                maxWidth: "unset",
                width: MOBILE_SIZE,
                height: "100%",
                transform: `translateX(-${MOBILE_SIZE - pageWidth - 120}px)`
              }} />
            </Flex>
          </Flex>
        </Flex>
      </PolygonMask>
    </Flex>
  )
})