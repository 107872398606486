import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
export const LazyImage = ({ src, alt = '', lazy = true, placeholder = _jsx(CircularProgress, {}), width = '100%', height = 'auto', style = {}, imgStyle = {}, loadingElement }) => {
    const [isLoading, setIsLoading] = useState(lazy);
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setIsLoading(false);
            setHasError(false);
        };
        img.onerror = () => {
            setIsLoading(false);
            setHasError(true);
        };
    }, [src]);
    return (_jsxs(Box
    // position="relative"
    , { 
        // position="relative"
        width: width, height: height, style: {
            userSelect: "none",
            ...style
        }, display: "flex", justifyContent: "center", alignItems: "center", children: [isLoading && (loadingElement || (_jsx(Box, { position: "absolute", top: "50%", left: "50%", style: { transform: 'translate(-50%, -50%)' }, children: placeholder }))), !hasError && (_jsx("img", { src: src, alt: alt, style: {
                    width: '100%',
                    height: '100%',
                    userSelect: "none",
                    opacity: isLoading ? 0 : 1,
                    transition: 'opacity 0.3s ease-in-out',
                    ...imgStyle
                } })), hasError && !isLoading && _jsx(Box, { children: "Error loading image" })] }));
};
