import { CONTENT_MAX_WIDTH, PAGE_MAX_WIDTH } from "@/utils/constants"
import { Link } from "@core-ui/react-core"
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core"
import logoFull from "@/assets/images/logo_full.png"
import ytubeIcon from "@/assets/images/icons/youtube.svg";
import facebookIcon from "@/assets/images/icons/facebook.svg";
import twitterIcon from "@/assets/images/icons/twitter.svg";
import instagramIcon from "@/assets/images/icons/instagram.png";
import linkedinIcon from "@/assets/images/icons/linkedin.png";
import { useGlobalStyles } from "@/styles/globalStyle";
import { HomeSection5 } from "./HomeSection5";
import { Animates, PolygonMask } from "@core-ui/react-animates";

const textStyle = {
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 450,
  fontSize: '14px',
  lineHeight: '20px',
  letterSpacing: '-0.02em',
  fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
  color: '#FAFAFA',
  fontVariationSettings: "'slnt' 0",
}

export const FooterContent = () => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex fullSize column justifyContent={"space-between"} center>
      <PolygonMask id="footer-content" config={{}}>
        <Flex fullSize column justifyContent={"space-between"} center>
          <HomeSection5 />
          <Flex fullWidth maxWidth={PAGE_MAX_WIDTH} className={globalStyles.backDropFilterBlur4}
            style={{
              backdropFilter: "blur(4px)",
              WebkitBackdropFilter: "blur(4px)",
            }}
          >
            <Flex column fullWidth
              px={2}
              bgcolor={"rgba(0,0,0,0.8)"}
              center
            >
              <Flex column fullWidth maxWidth={CONTENT_MAX_WIDTH}
                py={6}
              >
                <Flex justifyContent={"space-between"} fullWidth centerY>
                  <Flex cursorPointer onClick={() => {
                    document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
                  }}>
                    <LazyImage src={logoFull} style={{ height: 40, width: 240 }} />
                  </Flex>
                  <Flex centerY>
                    <Flex mx={1} className={globalStyles.iconHover}>
                      <Link to="https://youtube.com">
                        <LazyImage src={ytubeIcon} />
                      </Link>
                    </Flex>
                    <Flex mx={1} className={globalStyles.iconHover}>
                      <Link to="https://facebook.com">
                        <LazyImage src={facebookIcon} />
                      </Link>
                    </Flex>
                    <Flex mx={1} className={globalStyles.iconHover}>
                      <Link to="https://twiiter.com">
                        <LazyImage src={twitterIcon} />
                      </Link>
                    </Flex>
                    <Flex mx={1} className={globalStyles.iconHover}>
                      <Link to="https://instagram.com">
                        <LazyImage src={instagramIcon} />
                      </Link>
                    </Flex>
                    <Flex ml={1} className={globalStyles.iconHover}>
                      <Link to="https://linkedin.com">
                        <LazyImage src={linkedinIcon} />
                      </Link>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex height={"1px"} fullWidth my={6} bgcolor={"#FFFFFF1A"}></Flex>
                <Flex justifyContent={"space-between"} fullWidth>
                  <Text style={{
                    ...textStyle,
                    fontSize: 16,
                  }} color={"#FAFAFA"}>
                    CompanyName @ 202X. All rights reserved.
                  </Text>
                  <Flex centerY>
                    <Flex className={globalStyles.hoverUnderLine} mx={1}>
                      <Text style={{
                        ...textStyle,
                        fontSize: 16,
                      }} color={"#FAFAFA"}>
                        Fourteen
                      </Text>
                    </Flex>
                    <Flex className={globalStyles.hoverUnderLine} mx={1}>
                      <Text style={{
                        ...textStyle,
                        fontSize: 16,
                      }} color={"#FAFAFA"}>
                        Fifteen
                      </Text>
                    </Flex>
                    <Flex className={globalStyles.hoverUnderLine} ml={1}>
                      <Text style={{
                        ...textStyle,
                        fontSize: 16,
                      }} color={"#FAFAFA"}>
                        Sixteen
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </PolygonMask>
    </Flex >
  )
}