import { usePageWidth } from "@/hooks/usePageWidth";
import { Animates } from "@core-ui/react-animates";
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core";


const textStyle: any = {
  fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
  fontFamily: "Roboto Mono",
  fontSize: "56px",
  fontStyle: "normal",
  fontWeight: "100",
  lineHeight: "114.286%",
  letterSpacing: "-2.24px",
  textAlign: "left",
  whiteSpace: "nowrap",
}

export const GoatFiItem = ({
  title,
  description,
  icon,
  descriptionStyle = {},
  titleStyle = {},
  delay = 0,
  triangleDelay = 0,
  isMobile,
  id
}: {
  title: string;
  description: string;
  icon: string;
  descriptionStyle?: any;
  titleStyle?: any;
  delay?: number;
  triangleDelay?: number;
  isMobile?: boolean;
  id: string;
}) => {

  const pageWidth = usePageWidth();

  return (
    <Animates.GrowUpAppear delay={delay}>
      <Flex fullWidth center>
        <Flex mr={4} position={"relative"}>
          <Animates.TriangleFilling id={`triangle-filling-${id}`} delay={triangleDelay} />
        </Flex>
        <Flex>
          <Flex column>
            <Text color="#FAFAFA"
              style={{
                ...textStyle,
                fontSize: isMobile ? 44 : 56,
                ...titleStyle
              }}
            >
              {title}
            </Text>
            <Text
              color="#FAFAFA"
              mt={1}
              style={{
                ...textStyle,
                fontSize: isMobile ? 44 : 56,
                fontWeight: isMobile ? 500 : "normal",
                lineHeight: isMobile ? "143%" : "125%",
                ...descriptionStyle
              }}
            >
              {description}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Animates.GrowUpAppear>
  )
}