import { observer } from "@core-ui/react-mobx-state";
import { Flex, Text } from "@core-ui/react-mui-core";
import voteIcon from "@/assets/images/icons/vote.svg"
import boostIcon from "@/assets/images/icons/boost.svg"
import governIcon from "@/assets/images/icons/govern.svg"
import earnIcon from "@/assets/images/icons/earn.svg"
import { PurposeItem } from "@/components/common/PurposeItem"
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates } from "@core-ui/react-animates";

export const S4ContentMobile = observer(() => {

  return (
    <Flex fullWidth centerX minHeight={800}>
      <Flex fullWidth maxWidth={1054} column centerY px={2}>
        <Flex mt={6}>
          <IntersectionObserverView>
            <Text color={"#FFF"}
              style={{
                textAlign: "center",
                fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                fontFamily: "Roboto Mono",
                fontSize: 44,
                fontStyle: "normal",
                fontWeight: "100",
                lineHeight: "135%",
                letterSpacing: "-2.24px",
              }}
            >
              What is $GOAT for?
            </Text>
          </IntersectionObserverView>
        </Flex>

        <Flex fullWidth justifyContent={"flex-start"} alignItems={"flex-start"} mt={4} column>
          <Flex fullWidth justifyContent={"flex-start"} alignItems={"flex-start"} minHeight={120}>
            <IntersectionObserverView isInfinite>
              <Animates.SlideLeftAppear delay={0.25}>
                <PurposeItem
                  isMobile
                  icon={voteIcon}
                  title={"Vote"}
                  description={"on Challenges to earn Voting reward"}
                  background="linear-gradient(94deg, rgba(233, 171, 62, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideLeftAppear>
            </IntersectionObserverView>
          </Flex>

          <Flex fullWidth justifyContent={"flex-start"} alignItems={"flex-start"} mt={2} minHeight={120}>
            <IntersectionObserverView isInfinite>
              <Animates.SlideRightAppear delay={0.25}>
                <PurposeItem
                  isMobile
                  icon={governIcon}
                  title={"Govern"}
                  description={"Vote to intergrate which protocols and share profit"}
                  background="linear-gradient(94deg, rgba(3, 255, 171, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideRightAppear>
            </IntersectionObserverView>
          </Flex>

          <Flex fullWidth justifyContent={"flex-start"} alignItems={"flex-start"} mt={2} minHeight={120}>
            <IntersectionObserverView isInfinite>
              <Animates.SlideLeftAppear delay={0.25}>
                <PurposeItem
                  isMobile
                  icon={boostIcon}
                  title={"Boost"}
                  description={"boost your Trust Score (and earning)"}
                  background="linear-gradient(94deg, rgba(62, 131, 233, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideLeftAppear>
            </IntersectionObserverView>
          </Flex>

          <Flex fullWidth justifyContent={"flex-start"} alignItems={"flex-start"} mt={2} minHeight={120}>
            <IntersectionObserverView isInfinite>
              <Animates.SlideRightAppear delay={0.25}>
                <PurposeItem
                  isMobile
                  icon={earnIcon}
                  title={"Earn"}
                  description={"airdrop and protocol fees"}
                  background="linear-gradient(94deg, rgba(233, 62, 139, 0.20) 3.59%, rgba(1, 1, 1, 0.03) 108.17%)"
                />
              </Animates.SlideRightAppear>
            </IntersectionObserverView>
          </Flex >
        </Flex>
      </Flex >
    </Flex >
  )
})