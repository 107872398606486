import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage } from "@core-ui/react-mui-core";
import bg3 from "@/assets/images/bg/bg3.png"
import { AbsoluteContentStruct } from "../common";
import { S4Content } from "./S4Content";

export const HomeSection4 = observer(() => {

  return (
    <AbsoluteContentStruct
      bgComponent={<Flex fullSize>
        <LazyImage src={bg3} style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }} />
      </Flex>}
      contentCompnent={<S4Content />}
    />
  )
})