import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage } from "@core-ui/react-mui-core";
import bgs2 from "@/assets/images/bg/bgs2.png"
import bgs2_2 from "@/assets/images/bg/bgs2_2.png"
import { AbsoluteContentStruct } from "../common";
import { S2ContentMobile } from "./S2ContentMobile";
import { Layers } from "@/styles/layers";
import { usePageWidth } from "@/hooks/usePageWidth";
import { MOBILE_SIZE } from "@/utils/constants";
import { useGlobalStyles } from "@/styles/globalStyle";

export const MobileHomeSection2 = observer(() => {

  // const { tabletSizeDown } = useResponsive();
  const pageWidth = usePageWidth();
  const globalStyles = useGlobalStyles();

  return (
    <Flex fullWidth column bgcolor={"#000"} minHeight={1250} position={"relative"}
    className={globalStyles.backDropFilterBlur4}
      style={{
        backgroundImage: `url(${bgs2})`,
        objectFit: "cover",
        backgroundSize: `${MOBILE_SIZE}px 100%`,
        backgroundRepeat: "no-repeat",
        backdropFilter: "blur(4px)",
        WebkitBackdropFilter: "blur(4px)",
      }}
    >
      <AbsoluteContentStruct
        bgComponent={<Flex fullSize position={"relative"}>
          <Flex position={"absolute"} bottom={0} fullWidth zIndex={Layers.layer1}>
            <LazyImage src={bgs2_2} width={MOBILE_SIZE} height={"auto"} imgStyle={{
              maxWidth: "unset",
              width: MOBILE_SIZE,
              height: "auto",
              transform: `translateX(-${MOBILE_SIZE - pageWidth - 120}px)`
            }} />
          </Flex>
        </Flex>}
        contentCompnent={<S2ContentMobile />}
      />
    </Flex>
  )
})