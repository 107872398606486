import { observer } from "@core-ui/react-mobx-state";
import { Flex, Loading } from "@core-ui/react-mui-core";
import { HomeSection1 } from "@/components/home/HomeSection1";
import { HomeSection2 } from "@/components/home/HomeSection2";
import { HomeSection4 } from "@/components/home/HomeSection4";
import { Footer } from "@/components/home/Footer";
import { MOBILE_SIZE, PAGE_MAX_WIDTH } from "@/utils/constants";
import MobileView from "./mobile"
import { usePageWidth } from "@/hooks/usePageWidth";
import { useStore } from "@/store/index";
import { LoadingPage } from "@/components/LoadingPage";
import { BannerMenuBar } from "@/components/home/BannerMenuBar";
import { useEffect } from "react";
import "@core-ui/react-animates/dist/styles.css"

export const PAGE_LOAD_TIME = 2500

const HomeContainer = observer(() => {
  const { uiStore } = useStore();
  const pageWidth = usePageWidth();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      uiStore.pageLoading = false;
    }, PAGE_LOAD_TIME);

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <>
      {uiStore.pageLoading && <Flex position={"fixed"} zIndex={999999999}
        width={"100vw"}
        height={"100vh"}
        overflow={"hidden"}
      >
        <LoadingPage />
      </Flex>}
      {pageWidth <= MOBILE_SIZE ? <MobileView /> : <>
        {uiStore.showFixedMenu && <Flex position={"fixed"} center top={16} zIndex={9999} fullWidth>
          <BannerMenuBar isFixedMenu />
        </Flex>}
        <Flex fullWidth column centerY position={"relative"} height={"100vh"} bgcolor={"#000000"}
          style={{ overflowX: "hidden", overflowY: "auto" }}
        >
          <Flex fullWidth position={"relative"} column maxWidth={PAGE_MAX_WIDTH}>
            <div id="top"></div>
            <HomeSection1 />
            <div id="top-goat-fi"></div>
            <HomeSection2 />
            <HomeSection4 />
            <Flex fullWidth>
              <Footer />
            </Flex>
          </Flex>
        </Flex>
      </>
      }
    </>
  )
});

export default HomeContainer;
