import { observer } from "@core-ui/react-mobx-state";
import { S1Background2 } from "./S1Background2";
import { S1Background } from "./S1Background";
import { S1Content } from "./S1Content";
import { AbsoluteContentStruct } from "../common/AbsoluteContentStruct";

export const HomeSection1 = observer(() => {
  return (
    <AbsoluteContentStruct
      bgComponent={<S1Background />}
      contentCompnent={
        <AbsoluteContentStruct
          bgComponent={<S1Background2 />}
          contentCompnent={<S1Content />}
        />
      }
    />
  )
})