import { observer } from "@core-ui/react-mobx-state";
import { Flex } from "@core-ui/react-mui-core";

export const AbsoluteContentStruct = observer(({
  bgComponent,
  contentCompnent
}: {
    bgComponent: React.ReactNode;
    contentCompnent: React.ReactNode;
}) => {

  return (
    <Flex fullSize column center position={"relative"}>
      {bgComponent}
      <Flex position={"absolute"} bgcolor={"transparent"} fullSize>
        {contentCompnent}
      </Flex>
    </Flex>
  )
})