import { usePageWidth } from "@/hooks/usePageWidth";
import { useGlobalStyles } from "@/styles/globalStyle";
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core";

export const PurposeItem = ({
  icon,
  title,
  description,
  background,
  isMobile
}: {
  icon: string;
  title: string;
  description: string;
  background: string;
  isMobile?: boolean;
}) => {
  const globalStyle = useGlobalStyles();

  return (
    <Flex className={isMobile ? "" : globalStyle.cardHover} fullWidth width={isMobile ? "100%" : 420} centerY bgcolor={background}
      borderRadius={"16px"}
      p={3}
      style={{
        background,
      }}
    >
      <Flex center>
        <LazyImage src={icon} style={{ width: 56 }} />
      </Flex>
      <Flex column ml={2} alignItems={"flex-start"}>
        <Text color={"#FFF"}
          textAlign={"start"}
          style={{
            fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
            fontFamily: "Roboto",
            fontSize: isMobile ? 24 : 32,
            fontStyle: "normal",
            fontWeight: "650",
            lineHeight: "125%",
            letterSpacing: "-0.03em",
            fontVariationSettings: "'slnt' 0",
          }}
        >
          {title}
        </Text>
        <Text color={"#FFF"}
          textAlign={"start"}
          style={{
            fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
            fontFamily: "Inter",
            fontSize: isMobile ? 16 : 18,
            fontStyle: "normal",
            fontWeight: "450",
            lineHeight: "133.333%",
            letterSpacing: "-0.03em",
            fontVariationSettings: "'slnt' 0",
          }}>
          {description}
        </Text>
      </Flex>

    </Flex >
  )
}
