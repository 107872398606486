import { observer } from "@core-ui/react-mobx-state";
import { Flex } from "@core-ui/react-mui-core";
import { S3ContentMobile } from "./S3ContentMobile";

export const MobileHomeSection3 = observer(() => {

  return (
    <Flex fullWidth column bgcolor={"#000"} minHeight={800} position={"relative"}>
      <S3ContentMobile />
    </Flex>
  )
})