import { usePageWidth } from "@/hooks/usePageWidth";
import { useGlobalStyles } from "@/styles/globalStyle";
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { Animates } from "@core-ui/react-animates";
import { Flex, LazyImage, OutlinedButton, Text } from "@core-ui/react-mui-core";
import frameLinearBg from "@/assets/images/bg/frame-linear-bg.png";

export interface IProfileItemProps {
  image: string;
  width?: number;
  imageSize?: number;
  height?: number;
  delay?: number;
  expandUp?: boolean;
  id: string;
  textBgColor?: string;
  isMobile?: boolean;
  mobileImageSize?: number;
}

export const ProfileItem = ({
  image,
  imageSize = 130,
  height = 550,
  delay = 0,
  mobileImageSize = 120,
  isMobile
}: IProfileItemProps) => {
  const globalStyles = useGlobalStyles();
  const pageWidth = usePageWidth();

  return (
    <Flex position={"relative"} fullWidth center column my={1} height={isMobile ? "fit-content" : `${pageWidth * height / PAGE_MAX_WIDTH}px`}
    >
      <Flex center width={`${pageWidth * 250 / PAGE_MAX_WIDTH}px`} column style={{ cursor: "default", userSelect: "none" }}>
        <Animates.ExpandUp slideEffect delay={delay}>
          <LazyImage src={image} style={{
            width: isMobile ? mobileImageSize : `${imageSize * pageWidth / PAGE_MAX_WIDTH}px`,
          }} />
        </Animates.ExpandUp>
      </Flex>
      <Flex
        style={{
          transform: "translateY(-10px)",
        }}
        className={globalStyles.backDropFilterBlur4}
      >
        <Flex p={1.5} py={1}
          borderRadius={"8px"}
          bgcolor={"rgba(8, 3, 21, 0.51)"}
          style={{
            background: "#0000007f",
            backgroundImage: `url(${frameLinearBg})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Flex column={!isMobile}>
            <Text className={globalStyles.textRoboto16}>
              ROI
            </Text>
            <Text className={globalStyles.textRobotoBold16} color={"#F4DB25"} pl={isMobile ? 1 : 0} style={{ fontFamily: "Inter" }}>
              77.78%
            </Text>
          </Flex>
          <Flex center fullHeight>
            <Flex mx={1} width={"1px"} height={"50%"} bgcolor={"#899CFB"}></Flex>
          </Flex>
          <Flex>
            <Flex column={!isMobile}>
              <Text className={globalStyles.textRoboto16}>
                APY
              </Text>
              <Text className={globalStyles.textRobotoBold16} color={"#F4DB25"} pl={isMobile ? 1 : 0} style={{ fontFamily: "Inter" }}>
                417%
              </Text>
            </Flex>
            <Flex ml={1} center>
              <OutlinedButton style={{
                background: "#3858F5",
                border: "none",
                padding: "2px 16px",
                height: "fit-content",
                borderRadius: "8px"
              }}>
                <Text className={globalStyles.textRoboto16}>
                  Stake
                </Text>
              </OutlinedButton>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
