import { useGlobalStyles } from "@/styles/globalStyle";
import { Animates } from "@core-ui/react-animates";
import { Flex, Text } from "@core-ui/react-mui-core";
import frameLinearBg from "@/assets/images/bg/frame-linear-bg-2.png";

export const MapItem = ({
  title,
  description,
  delay = 0,
  isMobile
}: {
  title: string;
  description: string;
  delay?: number;
  isMobile?: boolean;
}) => {
  const globalStyles = useGlobalStyles();

  return (
    <Flex fullWidth p={2} justifyContent={"flex-start"}>
      <Flex fullWidth column center>
        <Text color={"#FAFAFA"}
          style={{
            fontFamily: "Roboto Mono",
            fontSize: isMobile ? 32 : 40,
            fontStyle: "normal",
            fontWeight: "100",
            lineHeight: "120%",
            letterSpacing: "-0.04em",
            textAlign: isMobile ? "center" : "left",
            textTransform: "uppercase",
          }}
        >{title}</Text>

        <Animates.ExpandDown delay={delay}>
          <Flex fullWidth center>
            <Flex
              fullWidth
              borderRadius={"8px"}
              mt={2}
              p={2}
              width={300}
              bgcolor={"#0000007f"}
              className={globalStyles.backDropFilterBlur4}
              style={{
                backdropFilter: "blur(4px)",
                WebkitBackdropFilter: "blur(4px)",
                background: "#0000007f",
                backgroundImage: `url(${frameLinearBg})`,
                backgroundSize: "100% 100%",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Text color="#FAFAFA"
                textAlign={"start"}
                style={{
                  fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                  fontFamily: "Roboto",
                  fontSize: 16,
                  fontStyle: "normal",
                  fontWeight: "450",
                  lineHeight: isMobile ? "24px" : "150%",
                  letterSpacing: isMobile ? 0 : "-0.32px",
                }}
              >{description}</Text>
            </Flex>
          </Flex>
        </Animates.ExpandDown>
      </Flex>
    </Flex>
  )
}