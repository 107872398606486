import { useGlobalStyles } from "@/styles/globalStyle";
import { Animates } from "@core-ui/react-animates";
import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, OutlinedButton, Text, useResponsive } from "@core-ui/react-mui-core";
import logoFull from "@/assets/images/logo_full.png"
// import { Select, MenuItem } from "@core-ui/react-mui-core/materials";
import frameLinearBg from "@/assets/images/bg/desktop_frline_bg_menu.png"
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { goToSection } from "@/utils/index";

export const BannerMenuBar = observer(({
  isFixedMenu
}: {
  isFixedMenu?: boolean;
}) => {
  const globalStyles = useGlobalStyles();
  const AppearAnimComponent = isFixedMenu ? Animates.ExpandWide : Animates.Shrink;
  const { tabletSizeDown } = useResponsive();

  return (
    <Flex fullWidth center maxWidth={PAGE_MAX_WIDTH}>
      {/* <Flex position={"relative"} fullWidth={isFixedMenu}> */}
      <Flex position={"relative"}>
        <AppearAnimComponent duration={0.35} type="decay">
          <Flex borderRadius={"16px"} border={"solid 1px #0551A5"}
            bgcolor={"linear-gradient(90deg, rgba(0, 0, 0, 0.40) 0%, rgba(7, 0, 43, 0.40) 100%)"}
            padding={"16px 40px"}
            centerY
            className={globalStyles.backDropFilterBlur5}
            style={{
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              backgroundImage: `url(${frameLinearBg})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              background: "#00000066",
            }}
            fullWidth
            justifyContent={"space-between"}
          >
            <Flex centerY cursorPointer onClick={() => {
              document.getElementById("top")?.scrollIntoView({ behavior: "smooth" })
            }}>
              <LazyImage src={logoFull} style={{ height: 32, width: 192 }} />
            </Flex>
            <Flex mx={10} centerY>
              <Flex mx={1} cursorPointer
                onClick={() => { goToSection("top-goat-fi") }}
              >
                <Text className={globalStyles.textRoboto14} >Goat-fi</Text>
                {/* <Select value="1">
                  <MenuItem value={1}><Text className={globalStyles.textRoboto14}>Goat-fi</Text></MenuItem>
                </Select> */}
              </Flex>
              <Flex mx={1} cursorPointer
                onClick={() => { goToSection("top-core-tech") }}
              >
                <Text className={globalStyles.textRoboto14}>Core Technology</Text>
                {/* <Select value="1">
                  <MenuItem value={1}><Text className={globalStyles.textRoboto14}>Core Technology</Text></MenuItem>
                </Select> */}
              </Flex>
            </Flex>
            <Flex centerY>
              <Text className={globalStyles.textRoboto14}>$GOAT</Text>
              <Animates.DrawingBorderButton style={{
                marginLeft: 32,
                background: "#3858F5",
                border: "none", padding: "4px 18px",
                height: "fit-content",
                borderRadius: "8px"
              }}>
                <OutlinedButton style={{
                  background: "#3858F5",
                  border: "none"
                }}>
                  <Text className={globalStyles.textRoboto16} style={{ whiteSpace: "nowrap" }}>{tabletSizeDown ? "Launch" : "Launch app" }</Text>
                </OutlinedButton>
              </Animates.DrawingBorderButton>
            </Flex>
          </Flex>
        </AppearAnimComponent>
      </Flex>
    </Flex>
  )
})