import { observer } from "@core-ui/react-mobx-state";
import { Flex, Text } from "@core-ui/react-mui-core";
import { MapItem } from "../common/MapItem";
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { PolygonMask } from "@core-ui/react-animates";

export const S3ContentMobile = observer(() => {

  return (
    <PolygonMask id="s3-mobi" config={{}}>
      <Flex fullSize centerY mt={4} position={"relative"} column>
        <Flex column fullWidth px={2}>
          <Text color={"#FFF"}
            style={{
              fontFamily: 'Roboto Mono',
              fontStyle: "normal",
              fontWeight: 100,
              fontSize: 44,
              lineHeight: "135%",
              letterSpacing: "-0.04em",
              whiteSpace: "wrap",
              textAlign: "center",
              fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
            }}
          >
            Core - technology
          </Text>
        </Flex>
        <Flex center fullWidth minHeight={200}>
          <IntersectionObserverView isInfinite>
            <MapItem
              isMobile
              delay={0.25}
              title="Strategy maps"
              description="A comprehensive, interactive map of yield strategies that help you make better decisions and help Goats adjust strategies flexibly"
            />
          </IntersectionObserverView>
        </Flex>
        <Flex center fullWidth minHeight={200}>
          <IntersectionObserverView isInfinite>
            <MapItem
              isMobile
              delay={0.35}
              title="Trust Score"
              description="A measure of reputation reimagined to be fully on-chain and socially validated, to help you vet Goats more accurately"
            />
          </IntersectionObserverView>
        </Flex>
        <Flex center fullWidth minHeight={200}>
          <IntersectionObserverView isInfinite>
            <MapItem
              isMobile
              delay={0.45}
              title="Goat lend"
              description="Borrow while your locked funds continue to grow, against the future value of your growing portfolio value, at lower rate thanks to Trust Score"
            />
          </IntersectionObserverView>
        </Flex>
      </Flex >
    </PolygonMask>
  )
})