import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage, Text } from "@core-ui/react-mui-core";
import { ProfileItem } from "../common/ProfileItem";
import avt1 from "@/assets/images/avt/avt1.png"
import avt2 from "@/assets/images/avt/avt2.png"
import avt3 from "@/assets/images/avt/avt3.png"
import avt4 from "@/assets/images/avt/avt4.png"
import avt5 from "@/assets/images/avt/avt5.png"
import goatLogo from "@/assets/images/logo.png"
import pyramicArrowLeft from "@/assets/images/icons/pyramic_arrow_left.svg"
import pyramicArrowRight from "@/assets/images/icons/pyramic_arrow_right.svg"
import { Layers } from "@/styles/layers";
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { BannerMenuBar } from "./BannerMenuBar";
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { usePageWidth } from "@/hooks/usePageWidth";
import { Animates } from "@core-ui/react-animates";
import { useStore } from "@/store/index";
import { useGlobalStyles } from "@/styles/globalStyle";
import { PAGE_LOAD_TIME } from "@/containers/home";

export const S1Content = observer(() => {

  const pageWidth = usePageWidth();
  const { uiStore } = useStore()
  const globalStyle = useGlobalStyles();

  const pageLoadingTime = PAGE_LOAD_TIME / 1000;

  return (
    <IntersectionObserverView
      stateCallback={(isElementInView?: boolean) => {
        uiStore.showFixedMenu = !isElementInView;
      }}
    >
      <Flex fullSize column centerY bgcolor={"transparent"} position={"relative"} zIndex={Layers.layer6}>
        <Flex position={"absolute"} top={`-${pageWidth * 130 / PAGE_MAX_WIDTH}px`} column center left={`calc(50% - ${pageWidth * 125 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-1"} image={goatLogo} delay={pageLoadingTime + 2.05} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 126 / PAGE_MAX_WIDTH}px`} column center left={`calc(50% - ${pageWidth * 340 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-2"} image={avt1} delay={pageLoadingTime + 1.65} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 220 / PAGE_MAX_WIDTH}px`} column center left={`calc(50% + ${pageWidth * 120 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-3"} image={avt2} delay={pageLoadingTime + 1.25} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 350 / PAGE_MAX_WIDTH}px`} column center left={`calc(50% - ${pageWidth * 125 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-4"} image={avt3} delay={pageLoadingTime + 0.85} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 510 / PAGE_MAX_WIDTH}px`} column center left={`calc(50% - ${pageWidth * 480 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-5"} image={avt4} delay={pageLoadingTime + 0.45} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 530 / PAGE_MAX_WIDTH}px`} column alignItems={"flex-end"} left={`calc(50% + ${pageWidth * 260 / PAGE_MAX_WIDTH}px)`}>
          <ProfileItem id={"profile-6"} image={avt5} delay={pageLoadingTime} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 680 / PAGE_MAX_WIDTH}px`} column alignItems={"flex-end"} left={`calc(50% - ${pageWidth * 600 / PAGE_MAX_WIDTH}px)`}>
          <LazyImage src={pyramicArrowLeft} />
        </Flex>
        <Flex position={"absolute"} top={`${pageWidth * 680 / PAGE_MAX_WIDTH}px`} column alignItems={"flex-end"} left={`calc(50% + ${pageWidth * 530 / PAGE_MAX_WIDTH}px)`}>
          <LazyImage src={pyramicArrowRight} />
        </Flex>
        <Flex position={"absolute"} top={16} left={16} maxWidth={pageWidth * 480 / PAGE_MAX_WIDTH} center>
          <Animates.GrowUpAppear>
            <Text color={"#FFF"}
              className={globalStyle.textRoboto40}
              style={{
                fontWeight: 500,
                lineHeight: "120%",
                letterSpacing: "-0.04em",
                textAlign: "left",
              }}
            >
              Climb the Yield Mountain with your trusted Goats
            </Text>
          </Animates.GrowUpAppear>
        </Flex>
        <Flex position={"absolute"} top={16} right={16} maxWidth={pageWidth * 480 / PAGE_MAX_WIDTH} center>
          <Animates.GrowUpAppear>
            <Text color={"#FFF"}
              className={globalStyle.textRoboto40}
              style={{
                fontWeight: 500,
                lineHeight: "120%",
                letterSpacing: "-0.04em",
                textAlign: "right",
              }}
            >
              Stake now and let the Goats manage your funds
            </Text>
          </Animates.GrowUpAppear>
        </Flex>
        <Flex position={"absolute"} bottom={"32px"} fullWidth center>
          <IntersectionObserverView isInfinite id="menu-bar">
            <BannerMenuBar />
          </IntersectionObserverView>
        </Flex>
      </Flex>
    </IntersectionObserverView>
  )
})
