import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
export const IntersectionObserverView = ({ children, className, id, isInfinite = false, stateCallback }) => {
    const [loaded, setLoaded] = useState(false);
    const ref = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setLoaded(true);
                    stateCallback?.(true);
                }
                else {
                    if (isInfinite) {
                        setLoaded(false);
                    }
                    stateCallback?.(false);
                }
            });
        });
        if (ref.current) {
            observer.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);
    return (_jsx("div", { id: id, ref: ref, className: `w-full min-h-10 ${className}`, children: loaded ? children : "" }));
};
