import { observer } from "@core-ui/react-mobx-state";
import { Flex, LazyImage } from "@core-ui/react-mui-core";
import mountain from "@/assets/images/bg/mountain.png"
import winnerLine from "@/assets/images/bg/winner_line.svg"
import { Layers } from "@/styles/layers";
import { PAGE_MAX_WIDTH } from "@/utils/constants";
import { usePageWidth } from "@/hooks/usePageWidth";

export const S1Background2 = observer(() => {

  const pageWidth = usePageWidth();

  return (
    <Flex fullSize column bgcolor={"transparent"}>
      <Flex fullWidth column bgcolor={"transparent"} position={"absolute"} overflow={"hidden"} zIndex={Layers.layer5} >
        <Flex fullWidth center bgcolor={"transparent"} zIndex={Layers.layer5}>
          <LazyImage
            src={mountain} style={{
              zIndex: Layers.layer5, height: `${pageWidth * 850 / PAGE_MAX_WIDTH}px`, maxHeight: 850
            }} />
        </Flex>
      </Flex>
      <Flex fullWidth column bgcolor={"transparent"} position={"absolute"} overflow={"hidden"} top={`${pageWidth * 200 / PAGE_MAX_WIDTH}px`} zIndex={Layers.layer6} >
        <Flex fullWidth center bgcolor={"transparent"} zIndex={Layers.layer6}>
          <LazyImage
            src={winnerLine} style={{ zIndex: Layers.layer6, height: `${pageWidth * 700 / PAGE_MAX_WIDTH}px`, maxHeight: 700 }} />
        </Flex>
      </Flex>
    </Flex >
  )
})