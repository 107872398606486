import { useDebounce } from "@core-utils/utils-helpers";
import { useEffect, useState } from "react";
import { PAGE_MAX_WIDTH } from "../utils";

export const usePageWidth = () => {
  const [pageWidth, setPageWidth] = useState(Math.min(window.innerWidth, PAGE_MAX_WIDTH));

  const handleResize = useDebounce(() => {
    setPageWidth(Math.min(window.innerWidth, PAGE_MAX_WIDTH));
  }, 300)

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, [])

  return pageWidth
}