import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import "./triangleStroke.style.css";
export const TriangleStroke = ({ width = 450, height = 450, stroke = "#56c5f9", id }) => {
    useEffect(() => {
        animateDashArray(0);
        function animateDashArray(iter) {
            if (iter === 1) {
                iter = 0;
            }
            const polygon = document.querySelector(`#triangle-svg-${id} polygon`);
            if (polygon) {
                polygon.style.strokeDasharray = "50";
                setTimeout(() => {
                    polygon.style.strokeDasharray = "25";
                }, 1500);
                setTimeout(() => {
                    polygon.style.strokeDasharray = "2";
                    iter++;
                    setTimeout(() => {
                        animateDashArray(1);
                    }, 1500);
                }, 3000);
            }
        }
    }, []);
    return (_jsx("div", { className: "triangle-svg", id: `triangle-svg-${id}`, children: _jsx("svg", { width: width, height: height, viewBox: "-4 -1 38 34", children: _jsx("polygon", { fill: "transparent", stroke: stroke, strokeWidth: "0.7", points: "15, 0, 30, 30, 0, 30" }) }) }));
};
export default TriangleStroke;
