import { CONTENT_MAX_WIDTH } from "@/utils/constants";
import { observer } from "@core-ui/react-mobx-state";
import { Flex, Text } from "@core-ui/react-mui-core";
import antidumping from "@/assets/images/bg/antidumping.png"
import burning from "@/assets/images/bg/burning.png"
import halving from "@/assets/images/bg/halving.png"
import endlessDemand from "@/assets/images/bg/endless_emand.png"
import { IntersectionObserverView } from "@core-ui/react-viewframe";
import { Animates } from "@core-ui/react-animates";
import { usePageWidth } from "@/hooks/usePageWidth";
import { Description } from "../common/Description";

export const HomeSection5 = observer(() => {
  const pageWidth = usePageWidth();

  return (
    <Flex fullWidth column center>
      <Flex fullWidth maxWidth={CONTENT_MAX_WIDTH} p={4}>
        <IntersectionObserverView>
          <Flex fullWidth column>
            <Flex fullWidth mb={2} height={469} px={1.5}>
              <Flex flex={2} alignItems={"flex-end"}>
                <Text color={"#FFF"}
                  style={{
                    fontFamily: 'Roboto Mono',
                    fontStyle: "normal",
                    fontWeight: 100,
                    fontSize: 56,
                    lineHeight: "64px",
                    letterSpacing: "-0.04em",
                    fontFeatureSettings: "'cv01' on, 'cv02' on, 'cv03' on, 'cv04' on, 'cv10' on",
                    textAlign: "left"
                  }}
                >
                  What is $GOAT scarce?
                </Text>
              </Flex>
              <Flex flex={3} pl={2.5}>
                <Animates.GrowDownAppear delay={0.5}>
                  <Flex bgcolor={"#242428"} column justifyContent={"flex-end"} overflow={"hidden"}>
                    <Description title={"Anti-dumping"}
                      bgImage={antidumping}
                      content="$GOAT stakers earn the penalty paid by early $GOAT unstakers, slowing down $GOAT unstaking"
                    />
                  </Flex>
                </Animates.GrowDownAppear>
              </Flex>
            </Flex>

            <Flex fullWidth>
              <Flex flex={2} fullSize px={1.5} height={908}>
                <Animates.GrowDownAppear delay={1}>
                  <Flex fullSize borderRadius={"16px"} overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                    <Description title={"Endless Demand"}
                      bgImage={endlessDemand}
                      content="more and more token is required to maximize Trust Score and win Challenges"
                    />
                  </Flex>
                </Animates.GrowDownAppear>
              </Flex>
              <Flex flex={3} fullSize px={1.5} column>
                <Flex fullWidth height={440}>
                  <Animates.SlideLeftAppear delay={1.5}>
                    <Flex fullSize borderRadius={"16px"} overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                      <Description title={"Burning"}
                        bgImage={burning}
                        content="all fees collected in $GOAT are burned"
                      />
                    </Flex>
                  </Animates.SlideLeftAppear>
                </Flex>
                <Flex fullWidth mt={3.5} height={440}>
                  <Animates.SlideLeftAppear delay={2}>
                    <Flex fullSize borderRadius={"16px"} overflow={"hidden"} bgcolor={"#242428"} column justifyContent={"flex-end"}>
                      <Description title={"Halving"}
                        bgImage={halving}
                        content="emission is halved every 24 months"
                      />
                    </Flex>
                  </Animates.SlideLeftAppear>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </IntersectionObserverView>
      </Flex>
    </Flex >
  )
})